<template>
    <div class="page">


        <div class="wrapper">
            <div class="sign-up">
                <div class="title">活动报名</div>
                <img :src="qrcode" alt="qrcode" />
                <div class="subtitle">
                    手机扫一扫
                </div>
                <div class="subtitle">
                    立即报名
                </div>

                <div class="footer">
                    <span :class="type === 'class1' ? 'active' : ''" @click="open('class1')">1</span>
                    <span :class="type === 'class2,class3' ? 'active' : ''" @click="open('class2,class3')">2</span>
                </div>
            </div>


            <div class="card">

                <div  v-for="item in filteredList" :key="item.label">

                <div class="title">{{ item.label }}</div>

                <div class="options">
                    <div class="option" v-for="option in item.options" :key="option.label">
                        <div  :class="type === 'class1' ? 'label' : 'large-label'">{{ option.label }}</div>

             
                        <div class="progress-bar-wrapper" v-if="type === 'class1'">
                                <div class="progress-bar" >
                                                    <div class="progress" :style="{width: (option.ratio < 10 && option.ratio > 0 ? 10 : option.ratio)  + '%'}"></div>
                                             
                                                </div>
                                                <div class="suffix">
                                                        <span class="value">{{option.ratio}}</span>
                                                        <span class="unit">%</span>
                                                    </div>
                        </div>
                    </div>
                </div>
    
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import { fetchConfig, fetchAnalysis } from '../../api/sign-up';
import QRCode from 'qrcode'

    export default {
        data() {
            return {
                type: '',
                list: [],
                analysis: [],
                filter: [],

                qrcode: 'https://liqi.space/liqi.jpg',
            
            }
        },


        computed: {
            filteredList() {
                if (this.filter.length > 0) {
                    return this.list.filter(item => this.filter.indexOf(item.type) > -1).map(item => {
                        return {
                            ...item,
                            options: Array.isArray(item.options) ? item.options.map(option => {
                                const obj = this.analysis.find(ana => ana.class1 === option.label);
                                return {
                                    ...option,
                                    ratio:  obj ? Math.round((parseInt(obj.cnt) / option.max) * 100) : 0
                                }
                            }) : []
                        }
                    })
                } else {
                    return []
                }
            }
        },

        mounted() {

            QRCode.toDataURL(window.location.href.replace('/data', ''))
  .then(url => {
    console.log(url)
    this.qrcode = url;
  })
  .catch(err => {
    console.error(err)
    alert('生成二维码失败')
  })


                fetchConfig().then(res => {
                    console.log('res',res)
                    if (res && Array.isArray(res.list)) {
                        this.list = res.list;
                    }
                }).finally(() => {
                    this.loading = false;
                })

            const { type } = this.$route.query;

            this.type = type;

            if (typeof type ==='string') {
                if (type.indexOf(',') > -1) {
                    this.filter.push(...type.split(','))
                } else {
                    this.filter.push(type)
                }
            }

            this.loop();
        },

        methods: {

            loop() {

                this.getData();

                setTimeout(() => {
                    this.loop();
                }, 3000);
            },

            getData() {
                fetchAnalysis().then(res => {
                    console.log('res',res)
                    if (res && Array.isArray(res.list)) {
                        this.analysis = res.list;
                    }
                })
            },

            open(type) {
                
                window.location.replace(`${window.location.href.replace(/type=.+/, 'type=' + type)}`)
                window.location.reload();
            }
        }
    }
</script>

<style scoped>
    .page {
        background-color: #000;
        color: #fff;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    .wrapper {
        min-width: 90vw;
        max-width: 1200px;
        margin: 0 auto;
        padding: 40px;
        display: flex;
    }

    .sign-up {
        margin-right: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sign-up .title {
        font-weight: bold;
        font-size: 40px;
        font-style: italic;
        margin-bottom: 40px;
    }

    .sign-up img {
        width: 240px;
        margin-bottom: 24px;
    }

    .sign-up .subtitle {
        font-size: 20px;
        line-height: 2;
    }

    .sign-up .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-top: 8px;
    }
    .sign-up .footer span {
        border: 1px solid #fff;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .sign-up .footer .active {
        background-color: #fff;
        color: #000;
    }

    .card {
        flex: 1;
        background-image: linear-gradient(to bottom, #01182d, #012926);
        padding: 40px;
        border-radius: 24px;
        display: grid;
        grid-template-columns: repeat(1,fr);
        gap: 40px;
    }

    .card .title {
        font-weight: bold;
        font-size: 32px;
        color: #19efe1;
    }

    .card .options {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 80px;
    }

    .card .label {
        font-size: 18px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .card .large-label {
        font-size: 28px;
        margin-top: 32px;
        margin-bottom: 0;
    }

    .progress-bar-wrapper {
        display: flex;
        align-items: center;
    }
    .progress-bar {
        border-radius: 24px;
        background-color: rgba(255, 255, 255, .3);
        flex: 1;
        margin-right: 16px;
    }
    .progress {
        background-image: linear-gradient(to right, rgb(225, 240, 135), #30c5e6);
        border-radius: 24px;
        height: 24px;
    }

    .suffix {
        /* position: absolute;
        top: -10px;
        right: -68px; */
    }
    .suffix .value {
        font-size: 28px;
        padding-right: 2px;
        font-weight: bold;
        color: rgb(72, 246, 165);
    }
    .suffix .unit {
        font-size: 14px;
    }
</style>